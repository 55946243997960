import { arrow, computePosition, offset, shift } from "@floating-ui/dom";
import { html, render } from "lit-html";
import { cn, debounce } from "~/js/helpers.js";

const oppositeSideMap = {
	top: "bottom",
	right: "left",
	bottom: "top",
	left: "right",
};

const unsafeHTML = (string) =>
	document.createRange().createContextualFragment(string);
const hasHover = (el) => el.matches(":hover");
const hasFocus = (el) => el.contains(document.activeElement);
const popperInstances = new Set();
const px = (n) => (n != null ? `${n}px` : "");

export class Popper {
	#clickable = false;
	#defaultClass;
	#hoverable = false;
	#open = false;
	#arrowEl;
	#floatingEl;
	#content;
	constructor(referenceElement, options = {}) {
		// Merge options with default values
		this.options = {
			arrow: true,
			class: "",
			placement: "bottom",
			trigger: "hover",
			onShow: null,
			...options,
		};

		this.#defaultClass = cn(
			"bg-white dark:bg-slate-800 border border-slate-900/10 font-normal dark:border-white/10 rounded-md shadow-md p-2",
			this.options.class,
		);

		this.#clickable = this.options.trigger === "click";
		this.#hoverable = this.options.trigger === "hover";
		this.referenceEl = referenceElement;

		this.#arrowEl = document.createElement("div");
		this.#arrowEl.className =
			"absolute h-2 w-2 bg-white dark:bg-slate-800 border-l border-t border-slate-900/10 dark:border-white/10 transform rotate-45 z-10";

		this.#floatingEl = document.createElement("div");
		this.#floatingEl.className = "absolute z-10";
		this.#floatingEl.style.display = "none";

		// Append the container after the reference element
		this.referenceEl.insertAdjacentElement("afterend", this.#floatingEl);

		// Initialize events
		this.events = [
			["focusin", this.#showHandler, !this.#clickable],
			["focusout", this.#hideHandler, true],
			["click", this.#showHandler, this.#clickable],
			["keydown", this.#hideHandler, this.#clickable],
			["mouseenter", this.#showHandler, this.#hoverable],
			["mouseleave", this.#hideHandler, this.#hoverable],
		];
		for (const element of [this.#floatingEl, this.referenceEl]) {
			if (element.tabIndex < 0) element.tabIndex = 0; // trigger must be focusable
			for (const [name, handler, cond] of this.events)
				if (cond) element.addEventListener(name, handler);
		}

		// Update popper position on resize
		window.addEventListener(
			"resize",
			debounce(() => {
				if (this.#open) this.#update();
			}, 100).bind(this),
		);

		popperInstances.add(this);
	}

	activate() {
		this.#clickable = true;
		this.#hoverable = true;

		// Add event listeners
		for (const element of [this.#floatingEl, this.referenceEl]) {
			for (const [name, handler, cond] of this.events)
				if (cond) element.addEventListener(name, handler);
		}
	}

	deactivate() {
		this.#clickable = false;
		this.#hoverable = false;
		this.#open = false;
		this.#floatingEl.style.display = "none";

		// Remove all event listeners
		for (const element of [this.#floatingEl, this.referenceEl]) {
			for (const [name, handler, cond] of this.events)
				if (cond) element.removeEventListener(name, handler);
		}
	}

	setContent(content) {
		if (typeof content === "object") {
			this.#content = content.innerHTML;
		} else {
			this.#content = content;
		}
		this.#render();
	}

	#render() {
		if (this.#content === undefined) return;
		if (typeof this.#content === "string") {
			this.#content = unsafeHTML(this.#content);
		}
		const html_ = html`
      <div class="${this.#defaultClass}">
        ${this.#content}
      </div>
      ${this.options.arrow ? this.#arrowEl : ""}
    `;
		render(html_, this.#floatingEl);
	}

	#update() {
		computePosition(this.referenceEl, this.#floatingEl, {
			placement: this.options.placement,
			middleware: [
				offset(8),
				shift(),
				this.options.arrow && arrow({ element: this.#arrowEl, padding: 10 }),
			],
		}).then(({ x, y, placement, middlewareData }) => {
			this.#floatingEl.style.left = px(x);
			this.#floatingEl.style.top = px(y);

			if (!this.options.arrow) return;

			this.#arrowEl.style.left = px(middlewareData.arrow.x);
			this.#arrowEl.style.top = px(middlewareData.arrow.y);

			const arrowSide = oppositeSideMap[placement.split("-")[0]];
			this.#arrowEl.style[arrowSide] = px(-this.#arrowEl.offsetWidth / 2);
		});
	}

	#showHandler = (ev) => {
		if (!this.#content) return;
		if (this.referenceEl === undefined) console.error("trigger undefined");
		if (ev.relatedTarget === this.#floatingEl) return;
		// Close the dropdown if it's already open and the reference element is clicked again
		if (this.#open && this.#clickable && ev.target === this.referenceEl) {
			this.#open = false;
			this.#floatingEl.style.display = "none";
			return;
		}
		if (this.#open) return;

		this.#open = true;
		this.#floatingEl.style.display = "block";
		this.#update();

		// Call the onShow callback if provided
		if (typeof this.options.onShow === "function") {
			this.options.onShow(this);
		}
	};

	#hideHandler = (ev) => {
		setTimeout(() => {
			const elements = [this.referenceEl, this.#floatingEl].filter(Boolean);
			if (ev.type === "mouseleave" && elements.some(hasHover)) return;
			if (ev.type === "focusout" && elements.some(hasFocus)) return;
			if (ev.type === "keydown" && ev.key !== "Escape" && this.#clickable)
				return;
			this.#open = false;
			this.#floatingEl.style.display = "none";
		}, 100);
	};
}
